import Mustache from 'mustache';
import I18n from '@app-utilities/i18n';
import PostmongerStore from '@app-utilities/postmonger';
import Template from './body.html';
import LoaderRowTemplate from './loader-row.html';
import AdditionalRowTemplate from './additional-row.html';

import '../datatable.scss';

export default function (dom, options) {
	this.initialize = () => {
		this.i18n = I18n(PostmongerStore.cultureCode);
		this.render();
	};

	this.render = () => {
		const templateOptions = Object.assign({}, options.templateOptions);

		dom.innerHTML = Mustache.render(Template, templateOptions);
		this.addEvents(options.events, templateOptions.pageIndex);
	};

	this.appendLoaderRow = () => {
		// First, remove load more items row from the DOM
		this.deleteRow('#load-more-items-row');

		dom.insertAdjacentHTML('beforeend', Mustache.render(LoaderRowTemplate, {
			text: this.i18n.get('mobilepush_inapp.loading')
		}));
	};

	this.deleteRow = (selector) => {
		const row = dom.querySelector(selector);

		if (!row) {
			return;
		}

		row.parentNode.removeChild(row);
	};

	this.appendAdditionalRow = (templateOptions) => {
		dom.insertAdjacentHTML('beforeend', Mustache.render(AdditionalRowTemplate, templateOptions));

		// Add click listener if eventHandler is provided
		if (templateOptions.onSelect && typeof templateOptions.onSelect === 'function') {
			dom.querySelector(`#${templateOptions.id}`).addEventListener('click', templateOptions.onSelect);
		}
	};

	this.appendItems = (templateOptions) => {
		// First, remove loader row from the DOM
		this.deleteRow('#loader-row');

		// Append
		dom.insertAdjacentHTML('beforeend', Mustache.render(Template, templateOptions));
		this.addEvents(options.events, templateOptions.pageIndex);

		// Clean up unused spinners
		Array.prototype.slice.call(dom.querySelectorAll('.loader')).forEach((e) => e.parentNode.removeChild(e));
	};

	this.addEvents = (events, pageIndex) => {
		// Listener for row click
		Array.prototype.slice.call(dom.querySelectorAll(`.slds-table tbody tr.slds-hint-parent[data-pageindex="${pageIndex}"]`)).forEach((row) => row.addEventListener('click', events.onSelectTableRow));
	};

	this.initialize();
}
