// const Postmonger = require('postmonger');
import Postmonger from 'postmonger';
import { DEFAULT_CULTURE_CODE } from '../constants';

const PostmongerStore = {
	connection: null,
	toJBPayload: {},
	cultureCode: DEFAULT_CULTURE_CODE,
	devMode: false,

	getConnection: () => {
		if (!PostmongerStore.connection) {
			PostmongerStore.connection = new Postmonger.Session();
		}

		return PostmongerStore.connection;
	}
};

export default PostmongerStore;
