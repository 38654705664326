import Mustache from 'mustache';
import PostmongerStore from '@app-utilities/postmonger';
import { DEFAULT_PAGE_SIZE } from '@app-constants';
import Template from './datatable.html';
import './datatable.scss';
import Header from './header';
import Body from './body';

export default function (dom, tableOptions, fetchData, fetchOptions) {
	let pageIndex = fetchOptions.page;
	let fetching = false;
	let maxDataCount;

	this.initialize = () => {
		this.render();
	};

	this.onStartFetch = (isAdditionalFetch) => {
		fetching = true;

		if (isAdditionalFetch) {
			dom.querySelector('tbody').classList.add('additional-fetching');
		} else {
			dom.querySelector('tbody').classList.add('fetching');
		}
	};

	this.onEndFetch = (isAdditionalFetch) => {
		fetching = false;

		if (isAdditionalFetch) {
			dom.querySelector('tbody').classList.remove('additional-fetching');
		} else {
			dom.querySelector('tbody').classList.remove('fetching');
		}
	};

	this.render = () => {
		dom.insertAdjacentHTML('beforeend', Mustache.render(Template));

		this.renderHeader();
		this.renderBody(); // Render empty body with spinner first

		// Fetch data to render body
		this.onStartFetch();
		fetchData(fetchOptions).then((data) => {
			this.onEndFetch();
			maxDataCount = data.count;

			this.renderBody(data.items);
		});
	};

	this.renderHeader = () => {
		const headerEl = dom.querySelector('thead');
		const headerOptions = {
			templateOptions: {
				headers: tableOptions.templateOptions.headers
			},
			events: {
				onSelectTableHeader: this.onSelectTableHeader
			}
		};

		this.header = new Header(headerEl, headerOptions);
	};

	this.onSelectTableHeader = (e) => {
		const headerEl = e.target.closest('th');
		const { orderby } = headerEl.dataset;
		const sortdirection = headerEl.dataset.sortdirection === 'DESC' ? 'ASC' : 'DESC'; // Toggle sort direction
		const newFetchOptions = {
			...fetchOptions,
			orderby,
			sortdirection,
			selectedMessageId: PostmongerStore.toJBPayload.configurationArguments.messageId
		}; // Option with sort data

		// Update sort direction in DOM
		headerEl.dataset.sortdirection = sortdirection;

		// Reset page index
		pageIndex = fetchOptions.page;

		// Update class names
		// 1. Remove 'sorted' class from all header cells
		// 2. Add 'sorted' class to selected header cell
		if (!headerEl.classList.contains('slds-is-sorted')) {
			Array.prototype.slice.call(dom.querySelectorAll('thead tr th')).forEach((cell) => {
				cell.classList.remove('slds-is-sorted');
			});
			headerEl.classList.add('slds-is-sorted');
		}

		this.renderBody(); // Render Spinner first and fetch data
		this.onStartFetch();
		fetchData(newFetchOptions).then((data) => {
			this.onEndFetch();
			maxDataCount = data.count;

			this.renderBody(data.items);
		});
	};

	this.renderBody = (items) => {
		const bodyEl = dom.querySelector('tbody');
		const bodyOptions = {
			templateOptions: {
				items,
				noItemsText: tableOptions.templateOptions.noItemsText,
				pageIndex
			},
			events: {
				onSelectTableRow: tableOptions.events.onSelectTableRow
			}
		};

		this.body = new Body(bodyEl, bodyOptions);

		if (!items) {
			return;
		}

		if (this.hasMoreData()) {
			this.addLoadMoreItemsRow();
		} else if (maxDataCount !== 0 && !document.body.contains(dom.querySelector('tbody #no-more-items-row'))) {
			this.body.appendAdditionalRow({
				id: 'no-more-items-row',
				text: tableOptions.templateOptions.noMoreItemsText
			});
		}
	};

	this.addLoadMoreItemsRow = () => {
		if (fetching) {
			return;
		}

		this.body.appendAdditionalRow({
			id: 'load-more-items-row',
			text: tableOptions.templateOptions.loadMoreItemsText,
			onSelect: () => {
				const sortedHeaderCell = dom.querySelector('thead .slds-is-sorted');
				const { orderby, sortdirection } = sortedHeaderCell.dataset;
				const newFetchOptions = {
					...fetchOptions,
					pageSize: DEFAULT_PAGE_SIZE,
					page: ++pageIndex,
					orderby,
					sortdirection,
					selectedMessageId: PostmongerStore.toJBPayload.configurationArguments.messageId
				};

				// Fetch additional data
				// 1. Add loader row during fetch
				// 2. Append additional rows
				// 3. Append no more items row or load more items row
				this.onStartFetch(true);
				this.body.appendLoaderRow();
				fetchData(newFetchOptions).then((data) => {
					this.onEndFetch(true);
					maxDataCount = data.count;

					this.body.appendItems({
						items: data.items,
						pageIndex
					});

					if (this.hasMoreData()) {
						this.addLoadMoreItemsRow();
					} else if (maxDataCount !== 0 && !document.body.contains(dom.querySelector('tbody #no-more-items-row'))) {
						this.body.appendAdditionalRow({
							id: 'no-more-items-row',
							text: tableOptions.templateOptions.noMoreItemsText
						});
					}
				});
			}
		});
	};

	this.hasMoreData = () => dom.querySelectorAll('tbody tr.slds-hint-parent').length < maxDataCount;

	this.initialize();
}
