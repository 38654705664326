import Main from '@app-containers/main';
import PostmongerStore from '@app-utilities/postmonger';
import { hasProperty } from '@app-utilities/helper';
import McLib from '@salesforce-mc/mc-lib';
import getMCUrl from '@salesforce-mc/mc-url';
import svg4everybody from 'svg4everybody';
import 'isomorphic-fetch';
import TimezoneUtils from './utilities/timezone';

svg4everybody();
let self;
const connection = PostmongerStore.getConnection();

const renderActivity = () => {
	const dom = document.getElementById('mount');
	document.body.classList.add('slds-wcag');

	self.main = new Main(dom);
};

const checkDevMode = () => {
	const hasLocalStorage = typeof window.localStorage === 'object';

	// Use dev mode if localStorage has a 'dev' property set to 'true'
	if (hasLocalStorage) {
		const devSwitch = localStorage.getItem('dev');

		PostmongerStore.devMode = (devSwitch !== null && devSwitch === 'true');
	}
};

window.onload = function () {
	checkDevMode();

	self = this;

	// If it's in dev mode, just render the activity
	if (PostmongerStore.devMode) {
		// Initialize PostmongerStore for dev mode
		PostmongerStore.toJBPayload.name = '';
		PostmongerStore.toJBPayload.configurationArguments = {};
		PostmongerStore.toJBPayload.metaData = {};
		PostmongerStore.userTimezone = TimezoneUtils.getTimezoneItemForComboboxById('47').name;

		renderActivity();
	} else {
		connection.trigger('ready');
	}
};

// Ready -> Init Activity -> requestedCulture
connection.on('initActivity', (payload) => {
	if (payload) {
		PostmongerStore.toJBPayload = payload;
	}

	connection.trigger('requestCulture');

	// Update Activity Done Button status
	connection.trigger('updateButton', {
		button: 'next',
		text: 'done',
		enabled: hasProperty(PostmongerStore.toJBPayload, 'configurationArguments') && hasProperty(PostmongerStore.toJBPayload.configurationArguments, 'messageId') && PostmongerStore.toJBPayload.configurationArguments.messageId.length > 0
	});
});

connection.on('requestedCulture', (cultureCode) => {
	PostmongerStore.cultureCode = cultureCode;

	const mcLib = new McLib({
		mc: window.top,
		allowedOrigin: getMCUrl(),
		callback: () => {
			console.log('InboxActivity: mclib.version = %s', mcLib.version);
			console.log('InboxActivity: mclib.verifiedMC = %s', mcLib.verifiedMC);

			mcLib.registerLogoutUrl(`${window.location.origin}/logout`);
			mcLib.getUserInfo((userInfo) => {
				if (userInfo.timeZone === undefined) {
					return; // If mclib is not available, skip it
				}

				const timezoneId = Number.isInteger(userInfo.timeZone.timeZoneId) ? userInfo.timeZone.timeZoneId.toString() : userInfo.timeZone.timeZoneId; // Convert Timezone Id to String
				PostmongerStore.userTimezone = TimezoneUtils.getTimezoneItemForComboboxById(timezoneId).name;

				renderActivity();
			});
		}
	});
});

connection.on('clickedNext', () => {
	connection.trigger('updateActivity', PostmongerStore.toJBPayload);
});
