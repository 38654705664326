import '../styles/index.scss';
import Mustache from 'mustache';
import Template from '@app-templates/main.html';
import PostmongerStore from '@app-utilities/postmonger';
import I18n from '@app-utilities/i18n';
import { fetchMessages } from '@app-actions/messages';
import Datatable from '@app-components/datatable';
import { DEFAULT_PAGE_SIZE } from '@app-constants';

const connection = PostmongerStore.getConnection();

export default function (dom) {
	const defaultMessageFecthOption = {
		pageSize: DEFAULT_PAGE_SIZE,
		page: 1,
		orderby: 'lastUpdated',
		sortdirection: 'DESC',
		selectedMessageId: PostmongerStore.toJBPayload.configurationArguments.messageId
	};

	const isFetching = () => {
		const bodyClassList = dom.querySelector('tbody').classList;

		return bodyClassList.contains('additional-fetching') || bodyClassList.contains('fetching');
	};

	this.initialize = function () {
		this.i18n = I18n(PostmongerStore.cultureCode);
		this.render();
	};

	this.render = () => {
		dom.insertAdjacentHTML('beforeend', Mustache.render(Template));
		this.renderTable();
	};

	this.onSelectTableRow = (e) => {
		if (isFetching()) {
			return;
		}

		const selectedRow = e.target.closest('tr');
		const messageName = selectedRow.dataset.messagename;

		// DOM: Row selection (Only select non-selected rows)
		// 1. Remove 'selected' class from all rows
		// 2. Add 'selected' class to selected row
		if (!selectedRow.classList.contains('selected')) {
			Array.prototype.slice.call(dom.querySelectorAll('tbody tr')).forEach((row) => {
				row.classList.remove('selected');
			});
			selectedRow.classList.add('selected');
		}

		// Set name, messageId and isConfigured
		PostmongerStore.toJBPayload.name = messageName;
		PostmongerStore.toJBPayload.configurationArguments.messageId = selectedRow.id;
		PostmongerStore.toJBPayload.metaData.isConfigured = true;

		// Enable Activity Done Button
		connection.trigger('updateButton', {
			button: 'next',
			text: 'done',
			enabled: true
		});

		// Print out payload for debugging purpose
		console.log(PostmongerStore.toJBPayload);
	};

	this.renderTable = () => {
		const messageTable = dom.querySelector('#message-table');
		const tableOptions = {
			templateOptions: {
				headers: [
					{ name: this.i18n.get('mobilepush_inapp.message'), sortable: true, orderby: 'name', sortdirection: 'DESC', defaultSort: false },
					{ name: this.i18n.get('mobilepush_inapp.app'), sortable: false },
					{ name: this.i18n.get('mobilepush_inapp.last_modified'), sortable: true, orderby: 'lastUpdated', sortdirection: 'DESC', defaultSort: true },
					{ name: this.i18n.get('mobilepush_inapp.custom_timeframe_scheduler_start_date'), sortable: true, orderby: 'startDate', sortdirection: 'DESC', defaultSort: false },
					{ name: this.i18n.get('mobilepush_inapp.custom_timeframe_scheduler_end_date'), sortable: true, orderby: 'endDate', sortdirection: 'DESC', defaultSort: false }
				],
				noItemsText: this.i18n.get('mobilepush_inapp.no_active_message'),
				noMoreItemsText: this.i18n.get('mobilepush_inapp.no_more_messages'),
				loadMoreItemsText: this.i18n.get('mobilepush_inapp.load_more')
			},
			events: {
				onSelectTableRow: this.onSelectTableRow
			}
		};

		new Datatable(messageTable, tableOptions, fetchMessages, defaultMessageFecthOption);
	};

	this.initialize();
}
