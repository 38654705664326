import checkResponseStatus from '@salesforce-mc/vanilla-slds/src/libs/check-response-status';
import fetchOptions from '@salesforce-mc/vanilla-slds/src/constants/fetch-options';
import { hasProperty } from '@app-utilities/helper';
import PostmongerStore from '@app-utilities/postmonger';
import I18n from '@app-utilities/i18n';
import moment from 'moment-timezone';
import { DATE_TIME_FORMAT } from '../constants';

const BASE_URL = '/fuelapi/push/v1/message/';
const i18n = I18n(PostmongerStore.cultureCode);
/**
 * @private
 * This is just a map to make finding messages easier.  It is populated when the messages are fetched.
 */
const messageIdMap = {};

// Set locale of moment for lastUpdated date format
moment.locale(i18n.getMomentLocale());

const fetchMessages = (params) => {
	let url = `${BASE_URL}?`;

	// App Sendable Status
	url += '&appSendableStatus=1';

	// Pagination
	url += `&$pageSize=${params.pageSize}`;
	url += `&$page=${params.page}`;

	// Sorting
	url += `&$orderBy=${params.orderby}%20${params.sortdirection}%20`;

	// Extra filter for message status and type
	url += '&$filter=statusid eq 2 and sendmethodid eq 3 and messagetype eq 8';

	return fetch(url, fetchOptions).then(checkResponseStatus).then((data) => {
		if (!data || !hasProperty(data, 'items')) {
			return {};
		}

		return data;
	}).then((data) => {
		if (!data) {
			return {};
		}

		return {
			count: data.count,
			items: data.items.map((message) => {
				messageIdMap[message.id] = Object.assign({}, message);

				return {
					id: message.id,
					name: message.name,
					lastModified: moment(message.lastUpdated).tz(PostmongerStore.userTimezone).format(DATE_TIME_FORMAT),
					appName: message.application.name,
					hasIcon: hasProperty(message.application, 'iconUrl') && message.application.iconUrl.length > 0,
					appIconSrc: hasProperty(message.application, 'iconUrl') ? message.application.iconUrl : '',
					selected: params.selectedMessageId && params.selectedMessageId === message.id,
					pageIndex: params.page,
					startDate: moment(message.startDate).tz(PostmongerStore.userTimezone).format(DATE_TIME_FORMAT),
					endDate: hasProperty(message, 'endDate') ? moment(message.endDate).tz(PostmongerStore.userTimezone).format(DATE_TIME_FORMAT) : ''
				};
			})
		};
	});
};

const getMessageById = (messageId) => messageIdMap[messageId] || {};

export { fetchMessages, getMessageById };
