import Mustache from 'mustache';
import Template from './header.html';
import '../datatable.scss';

export default function (dom, options) {
	const self = this;

	this.initialize = () => {
		self.render();
		self.addEvents(options.events);
	};

	this.render = () => {
		const templateOptions = Object.assign({}, options.templateOptions);

		dom.insertAdjacentHTML('beforeend', Mustache.render(Template, templateOptions));
	};

	this.addEvents = (events) => {
		// Listener for header click
		Array.prototype.slice.call(dom.querySelectorAll('.slds-table thead th.slds-is-sortable')).forEach((el) => el.addEventListener('click', events.onSelectTableHeader));
	};

	this.initialize();
}
